import React, {  } from 'react';
import './../../assets/styles/MainTitle.css'


const MainTitle = props => {
    let BOOK = 0;
    const currentBookID = props.currentBookID;
    for (let i = 0; i < props.payload.listData.length; i++) {
        const element = props.payload.listData[i];
        if (element.bookId === currentBookID) {
            BOOK = i;
        }
    }
    const ITEM = props.payload.listData[BOOK];
    return (
        <div id="main_title_container"  className="main_title_container">
            <p className="main_title_text">{ITEM.title ?? ''}</p>
            <p className="main_subtitle_text">{ITEM.subtitle ?? ''}</p>
        </div>
    );
}

export default MainTitle;
