import React, { useState } from 'react';
import Drawer from 'react-drag-drawer';
import Regulamento from './regulamento';
import SeuLivroAqui from './seuLivroAqui';
import './../../assets/styles/InfoSmallButtons.css';

const InfoSmallButtons = () => {
	const [show_terms, toggle_terms] = useState(false);
	const [show_participe, toggle_participe] = useState(false);
	return (
		<div id="small_buttons" className="info_small_buttons_container">
			<p onClick={() => toggle_terms(true)}>Regulamento</p>
			{/* <p>|</p>
			<p onClick={() => toggle_participe(true)}>Você é autor? </p>
			<p onClick={() => toggle_participe(true)}> Seu conto aqui.</p> */}

			<Drawer dontApplyListeners={true} open={show_terms} onRequestClose={() => toggle_terms(false)}>
				<Regulamento onRequestClose={() => toggle_terms(false)} />
			</Drawer>

			<Drawer
				dontApplyListeners={true}
				open={false}
				// open={show_participe}
				onRequestClose={() => toggle_participe(false)}
			>
				<SeuLivroAqui onRequestClose={() => toggle_participe(false)} />
			</Drawer>
		</div>
	);
};

export default InfoSmallButtons;
