import React, {  } from "react";
import TweenOne from "rc-tween-one";
import "./../../assets/styles/Disclaimer.css";

const Disclaimer = props => {
  const TITULO_SOBRE = 'Os livros da Máquina de Contos são obras literárias de responsabilidade de seus autores. Os contos são indicados para o público adulto e alguns temas ou conteúdos podem ser considerados sensíveis por parte dos leitores. Opiniões eventualmente expressas não refletem, necessariamente, a opinião da Graviola ou de empresas parceiras.'
  return (
    <TweenOne
      id="disclaimer"
      className={`disclaimer_container`}
      animation={{
        opacity: 1
      }}
    >
      <div>
        <div className="disclaimer_title">
          { TITULO_SOBRE }
        </div>
      </div>
    </TweenOne>
  );
};

export default Disclaimer;
