import React from 'react'

import Drawer from "react-drag-drawer";

import AudioPlayer from './components/AudioPlayer';

import './styles.css'

const AudioPlayerModal = ({
    isOpen,
    audioUrl,
    bookTitle,
    authorName,
    onHideModal,
    narratorName,
}) => {
    return (
        <Drawer dontApplyListeners={true} open={isOpen} onRequestClose={onHideModal}>
            <div className='modal-body'>
                <div className='modal-header'>
                    <button className='close-modal-button' onClick={onHideModal} />
                </div>

                <div className='modal-content'>
                    <div className='modal-content-title text-main-color'>
                        {bookTitle}
                    </div>

                    <div className='modal-content-subtitle text-main-color'>
                        {authorName}
                    </div>


                    <AudioPlayer audioUrl={audioUrl} />

                    <div className='modal-content-subtitle'>
                        <p className='text-main-color'>Narração:
                            <span
                                className='text-secondary-color'
                                style={{ padding: '0 0 0 8px' }}
                            >
                                {narratorName}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default AudioPlayerModal
