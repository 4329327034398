import '../../assets/styles/PushNotificationModal.css';
import BellSvg from '../../assets/images/bell.svg'

import React from 'react';
import { usePushContext } from '../../context/pushContext';

function PushNotificationModal({ showEnable }) {
	const {
		showPushModal,
		acceptPushNotification,
		rejectPushNotification,
	} = usePushContext();

	if(!showPushModal || !showEnable) return null;

	return (
		<div className="fog_background">
			<div className="modal_container">
				<div className='modal_content'>
					<p className='title'>Quer receber notificações e ser avisado sobre novos contos?</p>
					<img className='bell_icon' src={BellSvg} alt='Notificação' />
					<p className='subtitle'>Cancele quando quiser</p>
				</div>

				<div className="modal_footer">
					<button type="button" className="btn accept" onClick={acceptPushNotification}>
						<div>
							Aceito
						</div>
					</button>
					<button type="button" className="btn dismiss" onClick={rejectPushNotification}>
						<div>
							Agora não
						</div>
					</button>
				</div>
			</div>
		</div>
	);
}

export default PushNotificationModal;