import React, { useState } from "react";
import Drawer from "react-drag-drawer";
import FormCallUs from "./faleconosco-form/index";

const CallUsModal = ({
  show_callus,
  toggle_callus,
  isSuccess,
  setIsSuccess
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Drawer dontApplyListeners={true} open={show_callus} onRequestClose={() => toggle_callus(false)}>
      <div className="modal modal-callus">
        <div className="modal-container-callus">
          <h2 style={{ padding: 10, marginBlockEnd: 0 }}>FALE CONOSCO</h2>
          <p style={{ padding: 10, margin: 0 }}>
            Ficou com alguma dúvida ou quer dar sua opinião? Mande para a Máquina de Contos!
          </p>
          <FormCallUs
            toggle_callus={toggle_callus}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isSuccess={isSuccess}
            setIsSuccess={setIsSuccess}
          />
        </div>
      </div>
    </Drawer>
  );
};
export default CallUsModal;
