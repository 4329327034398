import React, { Component } from "react";
import TweenOne from "rc-tween-one";
import "./../../assets/styles/Preloader.css";

class Preloader extends Component {
  static defaultProps = {
    appState: null
  };

  state = {
    isAppInitialized: false
  };

  render() {
    // const screenWidth = window.innerWidth;
    const stateAppLoaded = this.props.appState === "inicializing"
      ? false
      : true;
    // if (this.state.isAppInitialized) {
    //   return null;
    // }
    return (
      <TweenOne
        className="preloader_animate"
        paused={!stateAppLoaded}
        animation={{
          // y: -screenWidth,
          // opacity: 0,
          onComplete: () =>
            this.setState({
              isAppInitialized: true
            })
        }}
      />
    );
  }
}

export default Preloader;
