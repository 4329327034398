import React from "react";
import "./../../assets/styles/Footer.css";
import logoSvg from "./../../assets/images/graviola-digital-logo.png";
import instagramLogoSvg from "./../../assets/images/instagram_icon.svg";

const Footer = ({ appState }) => {
  return (
    <div id="footer" className={"footer_container"}>
      <div
        className="footer_box"
        style={{ backgroundImage: `url(${logoSvg})` }}
      />
      <p>
        &copy; Copyright Graviola Digital 2023
        <br />
        Todos direitos reservados.
      </p>
      <div>
        <a href="https://www.instagram.com/gravioladigital_oficial/" target="_blank">
          <div
            className="footer_box_instagram"
            style={{ backgroundImage: `url(${instagramLogoSvg})` }}
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
