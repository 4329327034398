import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.css';
import App from './App';
import * as serviceWorker from './services/serviceWorker';
import firebase from 'firebase/app';

import PushContextProvider from './context/pushContext';
import PWAContextProvider from './context/pwaContext';

import { isMobile } from './utils/device'

if (firebase.messaging.isSupported()) {
	const firebaseConfig = {
		apiKey: "AIzaSyAaKE3MpO_7QeUcKqx2ZkBLzBGtHhlMC78",
		authDomain: "alesliterario.firebaseapp.com",
		projectId: "alesliterario",
		storageBucket: "alesliterario.appspot.com",
		messagingSenderId: "558947487249",
		appId: "1:558947487249:web:00b2034b9dba5cda112bb0",
		measurementId: "G-6GPVJ431YC"
	};
	
	firebase.initializeApp(firebaseConfig);
	firebase.analytics();

	const messaging = firebase.messaging();
	messaging.onMessage(function(payload) {
		if(payload?.data?.title && payload?.data?.body) {
			const { title, body } = payload?.data;

			const notification_options = {
				body,
				icon: 'https://www.maquinadecontos.com.br/logo512.png',
			}
	
			if(isMobile) {
				navigator.serviceWorker.getRegistrations().then((registrations) => {
					if(registrations) {
						registrations[0].showNotification(title, notification_options);
					}
				});
			} else {
				new Notification(title, notification_options);
			}
		}

	});
}

ReactDOM.render(
	<PushContextProvider>
		<PWAContextProvider>
			<App />
		</PWAContextProvider>
	</PushContextProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
