import React, { useState } from "react";
import Drawer from "react-drag-drawer";
import "./../../assets/styles/InfoButtons.css";
import CallUsModal from "../faleconosco/faleconosco-modal";

const styles = {
  paragraph: {
    // marginBlockStart: 0,
    // marginBlockEnd: 0,
    paddingTop: "0.5em",
    paddingBottom: "0.5em"
  }
};

const InfoButtons = () => {
  const [show_about, toggle_about] = useState(false);
  const [show_doubt, toggle_doubt] = useState(false);
  const [show_callus, toggle_callus] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  return (
    <div id="info_buttons" className="info_buttons_container">
      <div className="info_buttons_wrap">
        <button id="about" onClick={() => toggle_about(true)}>
          SOBRE NÓS
        </button>
        <button id="faq" onClick={() => toggle_doubt(true)}>
          DÚVIDAS
        </button>
        <button
          id="contact"
          onClick={() => {
            toggle_callus(true);
            setIsSuccess(false);
          }}
        >
          FALE CONOSCO
        </button>
        <button
          id="logout"
          onClick={() => {
            window.localStorage.removeItem('@mdc/hash');

            window.location.reload();
          }}
        >
          SAIR
        </button>
      </div>

      <Drawer dontApplyListeners={true} open={show_about} onRequestClose={() => toggle_about(false)}>
        <div className="modal">
          <div className="modal-container">
            <h2>SOBRE NÓS</h2>
            <p style={styles.paragraph}>
              Somos a Máquina de Contos, uma plataforma que entrega todos os
              meses um novo conto de autor brasileiro
              contemporâneo, para ler no smartphone, tablet ou computador.
            </p>
            <p style={styles.paragraph}>
              Buscamos levar aos nossos leitores sempre um título relevante
              deste que é um dos gêneros mais destacados na tradição literária
              brasileira. E com ele, levar também a pluralidade e
              representatividade de nossos autores e suas escritas, sem nunca
              esquecer o prazer da leitura.
            </p>
          </div>
          <div className="modal-button-container">
            <button onClick={() => toggle_about(false)}>FECHAR</button>
          </div>
        </div>
      </Drawer>

      <Drawer
        dontApplyListeners={true}
        modalElementClass="card"
        parentElement={document.body}
        direction="bottom"
        open={show_doubt}
        onRequestClose={() => toggle_doubt(false)}
      >
        <div className="modal">
          <div className="modal-container">
            <h2>DÚVIDAS</h2>

            <p style={styles.paragraph}>
              <strong>Como faço para ler os contos?</strong>
            </p>

            <p style={styles.paragraph}>

              Você pode ler online, se estiver conectado à internet, e ainda usar os recursos de acessibilidade, como a narração página a página ou escutar o conto como um audiolivro. Você pode ainda fazer o download do conto para ler offline, sem usar a internet. Para isso, você vai precisar de um leitor digital (e-reader). Há muitas opções gratuitas em sua loja de aplicativos. Recomendamos o Play Livros, para celulares e tablets Android, o Livros, para celulares e tablets iOS ou Adobe Digital Editions, para computadores. Se o conto não for carregado diretamente para a biblioteca do seu leitor digital, procure-o na área de downloads do seu dispositivo e faça o carregamento manual.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>Vou receber os livros na minha casa?</strong>

            </p>
            <p style={styles.paragraph}>

              Seus contos virão sempre na forma de um e-book, ou seja, um livro digital para ser lido online aqui na plataforma Máquina de Contos, ou offline, no leitor digital de sua preferência. Você não receberá o livro físico.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>Quando vou receber meu próximo conto?</strong>

            </p>
            <p style={styles.paragraph}>

              A cada início de mês a Máquina de Contos entrega um novo conto em formato de livro digital.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>Quantos contos eu vou receber?</strong>

            </p>
            <p style={styles.paragraph}>

              Você receberá sempre um novo conto no formato e-book no início de cada mês enquanto fizer parte da Máquina de Contos.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>
                Quanto tempo tenho para ler ou baixar um conto?
              </strong>

            </p>
            <p style={styles.paragraph}>

              Os contos ficam disponíveis para download por um ano após a publicação na plataforma. Uma vez que você baixe o livro, ele é seu em definitivo, mesmo que você não acesse mais a Máquina de Contos, desinstale o atalho do seu celular ou troque de aparelho. Por isso é importante fazer o download antes dos 12 meses. Neste período, você pode ler online e ouvir sempre que quiser.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>
                Baixei um conto, mas não aparece no meu leitor digital...
              </strong>

            </p>
            <p style={styles.paragraph}>

              Cada leitor digital apresenta um comportamento diferente. Em alguns, o livro é carregado automaticamente para a biblioteca quando você faz o download. Em outros, você precisa localizar o livro na área de downloads do seu dispositivo e carregá-lo manualmente para o leitor. Geralmente, ao localizar o arquivo na pasta de downloads e clicar sobre ele para abri-lo, o dispositivo apresenta os programas aptos a fazê-lo. Você deve, então, selecionar o seu leitor digital.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>Posso trocar o livro que recebi?</strong>

            </p>

            <p style={styles.paragraph}>

              Um dos propósitos da Máquina de Contos é encorajar nossos leitores conhecer diferentes autores e suas escritas, e desfrutar da pluralidade e diversidade propostas. Por isso, pedimos que você se aventure a ler os títulos que escolhemos para você com todo cuidado e assim conheça novas possibilidades.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>Se não baixar o conto num mês, posso baixá-lo depois?</strong>

            </p>

            <p style={styles.paragraph}>

              Pode! Você tem 12 meses a partir da publicação de um conto na plataforma para fazer o download. Observe que todos os livros da plataforma apresentam a data limite para baixar e ler online.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>Por que não encontro mais um conto na plataforma?</strong>

            </p>
            <p style={styles.paragraph}>

              Os livros contendo os contos ficam disponíveis para download e leitura online por 12 meses a partir da disponibilização na plataforma, esse é o tempo que você tem para baixar um livro e mantê-lo em definitivo ou ler online quantas vezes quiser.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>O conto baixado aparece com problemas no meu leitor online...</strong>

            </p>
            <p style={styles.paragraph}>

              Diferentes leitores digitais, de diferentes desenvolvedores, podem ter particularidades e eventualmente dificuldade para suportar determinados formatos de e-books. Embora os livros na Máquina de Contos tenham o formato mais comumente aceito pelos e-readers, pode haver incompatibilidade com o leitor que você escolheu. Recomendamos que você experimente um novo leitor ou utilize o Play Livros, em dispositivos Android, o Livros, em dispositivos iOS, ou Adobe Edital Editions em computadores, todos gratuitos.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>Não consigo acessar o áudio do conto baixado</strong>

            </p>
            <p style={styles.paragraph}>

              Os áudios, seja a narração página a página, seja o conto completo, só estão disponíveis online. Estes áudios não acompanham o download do livro, pois a maioria dos leitores digitais não suporta este recurso.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>Se eu trocar meu celular perco os contos já baixados?</strong>

            </p>
            <p style={styles.paragraph}>

              Você pode transferir os livros de um dispositivo para outro, desde que ambos sejam de seu uso pessoal. Pode também manter seus arquivos em mais de um dispositivo, como computador e celular, sem nunca os compartilhar com terceiros. Dependendo do seu dispositivo e do sistema operacional usando, é possível que sua biblioteca seja transferida automaticamente para o novo dispositivo na troca do celular. Se não for o caso, você pode fazer a transferência manual ou ainda fazer download novamente no dispositivo novo, desde que os livros ainda estejam disponíveis na plataforma.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>Para que público os contos são recomendados?</strong>

            </p>
            <p style={styles.paragraph}>

              O conteúdo da Máquina de Contos é destinado para o público adulto em geral, ou seja, pessoas com 18 anos ou mais. A despeito da criteriosa curadoria, alguns temas podem ser considerados sensíveis por parcelas específicas de leitores, por isso recomendamos critério ao leitor no download e leitura dos contos. Confiamos no seu julgamento!

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>Se os livros são meus, então posso compartilhá-los?</strong>

            </p>
            <p style={styles.paragraph}>

              Os livros contendo os contos são de sua propriedade, mas compartilhá-los equivale a distribuir fotocópias dos seus livros impressos. Assim como os livros físicos, os livros digitais estão protegidos por direitos autorais, e por isso é proibido compartilhar, reproduzir, copiar, distribuir e comercializar. Queremos que você compartilhe histórias, não livros! Quem respeita o autor respeita seus direitos.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>Como posso entrar em contato com o autor?</strong>

            </p>
            <p style={styles.paragraph}>

              Envie uma mensagem para a Máquina de Contos por meio do Fale Conosco solicitando o contato com determinado autor, ou com a mensagem que deseja que chegue a ele. Garantimos encaminhar sua mensagem e dados de contato, mas a resposta fica à critério do autor.

            </p>

            <br />

            <p style={styles.paragraph}>

              <strong>
                Não encontrou sua pergunta aqui? Então envie para a gente.
              </strong>
              <p>
                <a href="mailto:contato@maquinadecontos.com.br">

                  contato@maquinadecontos.com.br

                </a>
              </p>

            </p>

            <br />
          </div>
          <div className="modal-button-container">
            <button onClick={() => toggle_doubt(false)}>FECHAR</button>
          </div>
        </div>
      </Drawer>
      <CallUsModal
        show_callus={show_callus}
        toggle_callus={toggle_callus}
        isSuccess={isSuccess}
        setIsSuccess={setIsSuccess}
      />
    </div>
  );
};

export default InfoButtons;
