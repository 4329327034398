import React, { Component } from "react";
import { default_server } from "./services/api";
import smoothscroll from "smoothscroll-polyfill";
import DataManager from "./services/dataManager";
import Preloader from "./components/__preloader";
import Error404 from "./components/__error404";
import LandingPage from "./components/__landingpage";
import AuthPage from "./components/__authpage";
import Reader from "./components/_reader";
import "./assets/styles/App.css";
import { isIOSDevice } from "./utils/device";
import { PWAContext } from './context/pwaContext'
import { Regulamento } from "./components/Regulamento";

var DATA_MANAGER = DataManager;
const currentPathname = window.location.pathname;
const IS_REGULATION = currentPathname === '/regulamento';
const START_DELAY = IS_REGULATION ? 0 : 5000;
const HAS_SUBDOMAIN = currentPathname !== "/" && currentPathname.length > 1;
const axios = require("axios");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appState: "inicializing",
      data: undefined
    };
    // kick off the polyfill!
    smoothscroll.polyfill();
  }

  static contextType = PWAContext;

  async componentDidMount() {
    if (HAS_SUBDOMAIN) {
      const token = currentPathname.replace(/[/"]/g, '');
      await window.localStorage.setItem('@mdc/hash', token);
    }
    return this.inicialize();
  }

  inicialize() {
    const hash = window.localStorage.getItem('@mdc/hash');

    if (hash) {
      const paramId = currentPathname.replace(/[^a-zA-Z0-9]/g, "");
      const URL_COMPOSED = `${default_server}/getportfolio/${hash}?produto=C`;
      //const URL_COMPOSED = "http://www.mocky.io/v2/5e7f53eb2f00007e00bac2e8"; // <<== AUTORA
      //const URL_COMPOSED = "http://www.mocky.io/v2/5e6efa5a3300004e00f07621";
      axios({
        method: "get",
        timeout: 20000,
        url: URL_COMPOSED,
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          if (!"status" in response) {
            throw "status {str} not found";
          }
          if (response.status === 200 || response.status === 201) {
            if (
              "listData" in response.data &&
              response.data.listData.length === 0
            ) {
              throw `listData {arr} not found`;
            }
            DATA_MANAGER = new DataManager(response.data);
            return this.loadLandingpage();
          } else {
            throw `status: ${response.status}`;
          }
        })
        .catch(error => {
          window.localStorage.removeItem('@mdc/hash');
          return this.loadFromError();
        });
    } else {
      return this.loadFromLogin();
    }
  }

  loadFromError = () => {
    this.setState({ appState: "error404" });
  };

  loadLandingpage = () => {
    setTimeout(() => {
      this.setState({
        appState: "landingpage",
        data: DATA_MANAGER
      });
    }, START_DELAY);
  };

  loadFromLogin() {
    setTimeout(() => {
      this.setState({ appState: "login" });
    }, START_DELAY);
  }

  loadFromCredentialsPayload = payload => {
    DATA_MANAGER = new DataManager(payload);

    window.localStorage.setItem('@mdc/hash', DATA_MANAGER.token);

    // rola scroll ate o topo

    // window.scrollTo(0,0);
    // return this.loadFromAutoStart();

    document.getElementById("root").scrollIntoView({
      behavior: "smooth"
    });

    return this.loadLandingpage();
  };

  toggleFullScreen() {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }
  }

  changeReader = bookId => {
    if (!this.state.reader) {
      this.setState({ reader: bookId }, () => {
        if (!isIOSDevice) {
          this.toggleFullScreen()
          // this.context.invalidateTimeout()
        }
      });
    } else {
      this.setState({ reader: false }, () => {
        if (!isIOSDevice) {
          this.toggleFullScreen()
        }
      });
    }
  };

  render() {
    return (
      <div className={`App ${this.state.appState}`}>
        {
          IS_REGULATION
            ?
            <Regulamento />
            :
            <>
              <Preloader appState={this.state.appState} />

              <Error404 appState={this.state.appState} />

              <AuthPage
                appState={this.state.appState}
                startDelay={START_DELAY}
                payload={{}}
                onRefresh={this.loadFromCredentialsPayload}
                onError={this.loadFromError}
              />

              <LandingPage
                changeReader={this.changeReader}
                appState={this.state.appState}
                startDelay={START_DELAY}
                payload={this.state.data}
              />

              <Reader toggleFullScreen={this.toggleFullScreen} reader={this.state.reader} changeReader={this.changeReader} />
            </>
        }
      </div>
    );
  }
}

export default App;
