import React from "react";
import TweenOne from "rc-tween-one";
import "./../../assets/styles/Review.css";

const Review = props => {
  let BOOK = 0;
  const currentBookID = props.currentBookID;
  for (let i = 0; i < props.payload.listData.length; i++) {
    const element = props.payload.listData[i];
    if (element.bookId === currentBookID) {
      BOOK = i;
    }
  }
  const ITEM = props.payload.listData[BOOK];
  const REVIEW_CONTENT = ITEM.bookReviewText || "Carregando...";
  const REVIEW_AUTHOR = ITEM.bookReviewAuthorName || "Carregando...";
  const REVIEW_ABOUT = ITEM.bookReviewAuthorAbout || "Carregando...";
  const TITULO_SOBRE = "Sobre o Conto";
  return (
    <TweenOne
      id="review"
      className={`review_container`}
      animation={{
        opacity: 1
      }}
    >
      <div className="review_title">{TITULO_SOBRE}</div>

      <div className="review_text">{REVIEW_CONTENT}</div>

      <div className="review_curation">{REVIEW_AUTHOR + ", " + REVIEW_ABOUT}</div>
    </TweenOne>
  );
};

export default Review;
