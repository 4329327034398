import React from "react";
import TweenOne from "rc-tween-one";
import "./../../assets/styles/Author.css";

const Author = props => {
  let BOOK = 0;
  const currentBookID = props.currentBookID;

  for (let i = 0; i < props.payload.listData.length; i++) {
    const element = props.payload.listData[i];
    if (element.bookId === currentBookID) {
      BOOK = i;
    }
  }

  const ITEM = props.payload.listData[BOOK];
  const AUTHOR_TITLE = ITEM.bookAuthorGender === "F" ? "A autora" : "O autor";
  const AUTHOR_CONTENT = ITEM.bookAuthorBiography || "Carregando...";
  const AUTHOR_IMAGE_SRC =
    ITEM.bookAuthorPhoto ||
    "https://farmazon.com.br/wp-content/uploads/2019/04/favicon.png";

  return (
    <TweenOne
      id="author"
      className={`author_container`}
      animation={{
        opacity: 1
      }}
    >
      <div className="author">
        <div className="author_title">{AUTHOR_TITLE}</div>

        <div className="author_image">
          <img src={AUTHOR_IMAGE_SRC} />
        </div>

        <div className="author_text">{AUTHOR_CONTENT}</div>
      </div>
    </TweenOne>
  );
};

export default Author;
