import React from "react";

const SeuLivroAqui = props => {
  return (
    <div className="modal">
      <h2>SEU CONTO AQUI</h2>
      <p>Se você é autor e gostaria de ser publicado na Máquina de Contos, escreva para nós e conte o que sai da sua máquina de contos!</p>
      <p>
        <a href="mailto:contato@maquinadecontos.com.br">
          contato@maquinadecontos.com.br
        </a>
      </p>
      <div className="modal-button-container">
        <button onClick={props.onRequestClose}>FECHAR</button>
      </div>
    </div>
  );
};

export default SeuLivroAqui;
