import React, { useState } from 'react';
import { default_server } from './../../services/api';
import TweenOne from 'rc-tween-one';
import Drawer from 'react-drag-drawer';
import './../../assets/styles/BookDetail.css';
import Copyrights from '../copyrights';
import ImageCopyright from './../../assets/images/Selo-Direitos-Graviola-Digital.gif';
import AudioPlayerModal from '../AudioPlayerModal';

const BookDetail = (props) => {
	let BOOK = 0;

	const [audioPlayerModalIsOpen, setAudioPlayerModalIsOpen] = useState(false)

	const isSurprise = false;
	const currentBookID = props.currentBookID;
	for (let i = 0; i < props.payload.listData.length; i++) {
		const element = props.payload.listData[i];
		if (element.bookId === currentBookID) {
			BOOK = i;
		}
	}

	const TOKEN = props.payload.token;
	const ITEM = props.payload.listData[BOOK];
	const AUTHOR = ITEM.bookAuthor || 'Carregando...';
	const TITLE = ITEM.bookTitle || 'Carregando...';
	const NARRATOR = ITEM.bookAudioFullSpeaker || 'Carregando...';
	const BOOK_URL = ITEM.bookDownloadFile || '#';
	const BOOK_AUDIO_FULL_URL = ITEM.bookAudioFull;

	const [show_how, toggle_how] = useState(false);
	const [show_download, toggle_download] = useState(false);

	const axios = require('axios');
	const postDownloadCounter = (type) => {
		axios.post(
			`${default_server}/quembaixou`,
			{
				livro: TITLE,
				token: TOKEN,
				produto: 'C',
				tipo: type
			},
			{
				timeout: 10000,
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
	};

	const downloadRelease = () => {
		document.getElementById('main_title_container').scrollIntoView({
			behavior: 'smooth'
		});
		toggle_download(true);
		setTimeout(() => {
			toggle_download(false);
			postDownloadCounter(1);
			window.open(BOOK_URL, '_self');
		}, 5000);
	};

	const resetGif = () => {
		var img = document.getElementById('book_copyright_gif');
		img.src = '';
		img.src = ImageCopyright;
	};

	return (
		<TweenOne paused={isSurprise} animation={{ opacity: 1 }} id="book_detail" className={`book_detail_container`}>
			{/* <p className="book_detail_infos">{content}</p> */}
			<br />
			{/* <button style={{ transform: "skewX(45deg)" }} id="how-to-read" onClick={() => props.changeReader(ITEM)}>
        LER ONLINE
      </button> */}

			<button
				style={{ transform: 'skewX(-45deg)' }}
				id="how-to-read"
				onClick={() => {
					props.changeReader(ITEM);
					postDownloadCounter(2);
				}}
			>
				<div
					style={{
						transform: 'skewX(45deg)',
						fontFamily: 'Project Bold',
						fontSize: '18px',
					}}
				>
					{' '}
					Ler Online
				</div>
			</button>

			<button
				disabled={!('bookDownloadFile' in ITEM)}
				id="download"
				style={{ transform: 'skewX(-45deg)' }}
				onClick={() => downloadRelease()}
			>
				<div
					style={{
						transform: 'skewX(45deg)',
						fontFamily: 'Project Bold',
						fontSize: '18px'
					}}
				>
					{' '}
					Baixar
				</div>
			</button>
			{BOOK_AUDIO_FULL_URL && (
				<button
					style={{ transform: 'skewX(-45deg)' }}
					id="how-to-read"
					onClick={() => {
						setAudioPlayerModalIsOpen(true);
					}}
				>
					<div
						style={{
							transform: 'skewX(45deg)',
							fontFamily: 'Project Bold',
							fontSize: '18px'
						}}
					>
						{' '}
						Ouvir
					</div>
				</button>
			)}

			<br />
			<div style={{ paddingBottom: '30px' }}>
				<a className="book_detail_link" href={'#'} onClick={() => toggle_how(true)}>
					{` Saiba mais... `}
				</a>
			</div>

			<Drawer dontApplyListeners={true} open={show_how} onRequestClose={() => toggle_how(false)}>
				<div className="modal">
					<div className="modal-container">
						<h2>SAIBA MAIS</h2>
						<p style={{ padding: '0px 20px 0px 20px' }}>
							Você pode ler o conto online aqui mesmo na plataforma, se estiver conectado à internet, e pode também usar os recursos de acessibilidade, como a narração página a página, ou ainda ouvir o conto como um audiolivro.
						</p>
						<p style={{ padding: '0px 20px 0px 20px' }}>
							Se preferir baixar para ler offline, você vai precisar de um leitor digital (e-reader). Há muitas opções gratuitas em sua loja de aplicativos. Recomendamos o Play Livros, para celulares e tablets Android, o Livros, para celulares e tablets iOS ou Adobe Digital Editions, para computadores. Se o conto não for carregado diretamente para a biblioteca do seu leitor digital, procure-o na área de downloads do seu dispositivo e faça o carregamento manual.
						</p>
						<p style={{ padding: '0px 20px 0px 20px' }}>
							Você tem 12 meses, a partir da publicação do conto na plataforma, para fazer o download. Durante este período, você pode ler e ouvir online. Os contos baixados são seus, mas lembre-se que todo o material é protegido por direitos autorais, por isso é proibido enviar a terceiros, reproduzir, distribuir, copiar ou comercializar.
						</p>


					</div>
					<div className="modal-button-container">
						<button onClick={() => toggle_how(false)}>FECHAR</button>
					</div>
				</div>
			</Drawer>

			<Drawer dontApplyListeners={true} open={show_download} onOpen={resetGif}>
				<Copyrights image={ImageCopyright} />
			</Drawer>

			<AudioPlayerModal
				bookTitle={TITLE}
				authorName={AUTHOR}
				narratorName={NARRATOR}
				isOpen={audioPlayerModalIsOpen}
				audioUrl={BOOK_AUDIO_FULL_URL}
				onHideModal={() => setAudioPlayerModalIsOpen(false)}
			/>
		</TweenOne>
	);
};

export default BookDetail;
