import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import { isMobile, isPWAInstaledIOS, isSupportedInstallPrompt } from '../utils/device';
import { sleep } from '../utils/helper';
import { usePushContext } from './pushContext';

export const PWAContext = createContext({});

function PWAContextProvider({ children }) {
  const [showPWAModal, setShowPWAModal] = useState(false);
  const timeoutRef = useRef(null);

  const [showPWAModalIOS, setShowPWAModalIOS] = useState(isPWAInstaledIOS ? false : true);

  // Created it to show PWA modal only one time per refresh
  const initalShowPWAModalRef = useRef(true);

  function handleDismissIOSPWAModal() {
    setShowPWAModalIOS(false);
  }

  const { showNotificationModal, verifyIfEnabledPushNotification } = usePushContext();

  // Here should be has the before install prompt event
  const installAssistentRef = useRef(null);
  function invalidateTimeout() {
    clearTimeout(timeoutRef.current)
  }

  async function handleAwaitForOneMinutesToShowPushModal() {
    const permission = verifyIfEnabledPushNotification();

    if(permission === 'default') {
      const one_second = 1000;
      const one_minute = 60;
      const minutes = 0.75;

      const timeForSleep = one_second * (one_minute * minutes)

      timeoutRef.current = setTimeout(() => {
        // Call here action to show push notification modal immediately
        showNotificationModal();
      }, timeForSleep);

    }
	}

  /**
   * Inject manifest.json inside of html for the browser know that application is PWA too
   */
  function injectManifestInsideOfHTML() {
    const link = document.createElement("link");
		link.rel = "manifest";
		link.setAttribute('href', `${process.env.PUBLIC_URL}/manifest.json`);

		document.getElementsByTagName("head")[0].appendChild(link);
  }

  function handleDismissPWAModal() {
    setShowPWAModal(false);

    initalShowPWAModalRef.current = false;

    handleAwaitForOneMinutesToShowPushModal();
  }

  function installPWAException(data) {
    console.log('>> Unexpected exception received on try install PWA', data);
  }

  function handleAcceptPWA() {
    handleDismissPWAModal();

    if(installAssistentRef.current) {
      installAssistentRef.current.prompt();

      // eslint-disable-next-line no-unused-expressions
      installAssistentRef.current?.userChoice.then(function(choiceResult) {
        handleAwaitForOneMinutesToShowPushModal();
      }, installPWAException);
    }
  }

  function handleShowPWAModal() {
    setShowPWAModal(true);
  }

  useEffect(() => {
		if(isSupportedInstallPrompt && isMobile) {
      /**
       * Add event listener to receive the PWA prompt install event and call
       *
       * OBS: If this event never be called, never be shown custom modal and any other
       */
      window.addEventListener('beforeinstallprompt', (event) => {
				event.preventDefault();

				installAssistentRef.current = event;

        if(initalShowPWAModalRef.current) {
            handleShowPWAModal()
        };
			});
    }
  }, []);

  return (
    <PWAContext.Provider
      value={{
        showPWAModal,
        dismissIOSPWAModal: handleDismissIOSPWAModal,
        injectManifest: injectManifestInsideOfHTML,
        rejectPWA: handleDismissPWAModal,
        acceptPWA: handleAcceptPWA,
        invalidateTimeout,
        showPWAModalIOS,
      }}>
      {children}
    </PWAContext.Provider>
  )
}

export function usePWAContext() {
  const context = useContext(PWAContext);

  if(!context) throw new Error('Should be use the usePWAContext inside of PWAContextProvider');

  return context;
}

export default PWAContextProvider;
