import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import InputMask from "react-input-mask";
import Drawer from "react-drag-drawer";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import axios from "axios";

import { default_server } from "./../../services/api";
import "./../../assets/styles/AuthMethodLogin.css";
import imgLoading from "./../../assets/images/p-loader.gif";

const AuthMethodLogin = (props) => {
  const [method, setMethod] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const screeHeight = window.screen.height;

  const buttonLabelDefault = "Entrar";
  const [BUTTON_LABEL, setButtonLabel] = useState(buttonLabelDefault);
  const regexTel =
    /^\(\d{2}\) \d{5}-\d{4}|\((?:1[2-9]|[2-9]\d)\) [5-9]\d{3}-\d{4}$/;
  const regexCnpjCpf =
    /^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$/;
  const [errorMsg, setErrorMessage] = useState("");
  const { phoneInput, toggle_callus } = props;
  const [radioCheck, setRadio] = useState(false);

  useEffect(() => {
    const buttonCPF = document.getElementById("button-cpf");
    if (!phoneInput && buttonCPF) {
      buttonCPF.disabled = !radioCheck;
    }
  }, [phoneInput, radioCheck]);

  useEffect(() => {
    phoneInput && setErrorMessage("");
  }, [phoneInput]);

  useEffect(() => {
    setLoading(true);
    axios(`${default_server}/operadora/?produto=C`).then((response) => {
      setData(response.data.lista_operadoras);
      setLoading(false);
    });
  }, []);

  const getFields = (
    field,
    values,
    touched,
    errors,
    handleChange,
    handleBlur
  ) => {
    console.log(errors.cpfcnpj, touched.cpfcnpj);
    if (field.name === "cpfcnpj") {
      if (field.validation !== "cpf_cnpj") {
        return (
          <div className="inputWrapper">
            <input
              className="input-cpf"
              value={values.cpfcnpj}
              name={field.name}
              key={field.name}
              placeholder={field.placeholder}
              pattern={!new RegExp(field.validation)}
              required
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.cpfcnpj && touched.cpfcnpj && (
              <p className="auth_login_error">{errors.cpfcnpj}</p>
            )}
          </div>
        );
      } else {
        return (
          <div className="inputWrapper">
            <InputMask
              value={values.cpfcnpj}
              name={field.name}
              key={field.name}
              mask={
                values.cpfcnpj.length <= 14
                  ? "999.999.999-9999999"
                  : "99.999.999/9999-99"
              }
              placeholder="CPF / CNPJ"
              maskChar={null}
              onChange={handleChange}
              className="input-cpf"
              onBlur={handleBlur}
            />
            {errors.cpfcnpj && touched.cpfcnpj && (
              <p className="auth_login_error">{errors.cpfcnpj}</p>
            )}
          </div>
        );
      }
    }

    if (field.name === "msisdn") {
      return (
        <div className="inputWrapper">
          <InputMask
            type="tel"
            mask="(99) 99999-9999"
            maskChar={null}
            name={field.name}
            key={field.name}
            value={values.msisdn}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={field.placeholder}
            required
            onClick={() => window.scrollTo(0, screeHeight * 0.25)}
          />
          {errors.msisdn && touched.msisdn && (
            <p className="auth_login_error">{errors.msisdn}</p>
          )}
        </div>
      );
    }

    if (field.name === "username") {
      return (
        <div className="inputWrapper">
          <input
            type="text"
            name={field.name}
            placeholder={field.placeholder}
            key={field.name}
            value={values.username}
            required
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.username && touched.username && (
            <p className="auth_login_error">{errors.username}</p>
          )}
        </div>
      );
    }

    if (field.name === "password") {
      return (
        <div className="inputWrapper">
          <input
            key={field.name}
            type="password"
            name="password"
            placeholder={field.placeholder}
            required
            onBlur={handleBlur}
            pattern={field.validation}
            value={values.password}
            onChange={handleChange}
            onClick={() => window.scrollTo(0, screeHeight * 0.25)}
          />
          {errors.password && touched.password && (
            <p className="auth_login_error">{errors.password}</p>
          )}
        </div>
      );
    }
  };

  if (!method) {
    return (
      <div className="auth-method-login">
        <p className="auth_method_title">
          ESCOLHA A FORMA DE
          <br /> FAZER LOGIN
        </p>
        <div className="auth_method_list">
          {loading ? (
            <img
              src={imgLoading}
              alt={"Loading..."}
              className="auth_method_list_loading"
            />
          ) : (
            data.map((item, index) => {
              return (
                <div
                  className="auth_method_item"
                  onClick={() => setMethod(item)}
                  key={index}
                >
                  <div className="auth_method_item_infos">
                    <img src={item.icon} alt={`item${index}`} />
                    <span>{item.operadora}</span>
                  </div>
                  <MdKeyboardArrowRight size={40} color="#000000AA" />
                </div>
              );
            })
          )}
        </div>
        <div className="auth_method_contact">
          <button
            onClick={() => toggle_callus(true)}
            id="contact"
            className="auth_call_us"
          >
            FALE CONOSCO
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="auth_login_container">
      <div className="auth_method_item" onClick={() => setMethod(!method)}>
        <div className="auth_method_item_infos">
          <MdKeyboardArrowLeft size={40} color="#000000AA" />
          <img src={method.icon} alt={method.operadora} />
          <span>{method.operadora}</span>
        </div>
      </div>
      <Formik
        initialValues={{ msisdn: "", password: "", cpfcnpj: "", username: "" }}
        validate={(values) => {
          const errors = {};

          method.formfieldslogin.map((field) => {
            if (field.name === "username") {
              if (!new RegExp(field.validation).test(values.username)) {
                errors.username = field.validation_message;
              }

              if (values.username === "") {
                errors.username = "Campo obrigatório";
              }
            }

            if (field.name === "cpfcnpj") {
              if (field.validation === "cpf_cnpj") {
                if (values.cpfcnpj.match(regexCnpjCpf) === null) {
                  errors.cpfcnpj = field.validation_message;
                }
              } else {
                if (!new RegExp(field.validation).test(values.cpfcnpj)) {
                  errors.cpfcnpj = field.validation_message;
                }

                if (values.cpfcnpj === "") {
                  errors.cpfcnpj = "Campo obrigatório";
                }
              }
            }
            if (field.name === "password") {
              if (!new RegExp(field.validation).test(values.password)) {
                errors.password = field.validation_message;
              }

              if (values.password === "") {
                errors.password = "Campo obrigatório";
              }
            }

            if (field.name === "msisdn") {
              if (values.msisdn.match(regexTel) === null) {
                errors.msisdn = field.validation_message;
              }

              if (values.msisdn === "") {
                errors.msisdn = "Campo obrigatório";
              }
            }
          });

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          props.onSubmit();
          setButtonLabel("• • •");
          const { idp, auth } = method;
          const data = {
            idp,
            auth: auth.toString(),
            produto: "C",
            tipo: "3",
          };

          const msisdn =
            values.msisdn && "55" + values.msisdn.replace(/[^a-zA-Z0-9]/g, "");

          if (!!msisdn) {
            data.msisdn = msisdn;
          }

          const password = values.password;

          if (!!password) {
            data.password = password;
          }

          const cpfcnpj = values.cpfcnpj;
          if (!!cpfcnpj) {
            data.cpfcnpj = cpfcnpj.replace(/[^0-9]+/g, "");
          }

          const username = values.username;

          if (!!username) {
            data.username = username;
          }

          axios({
            method: "post",
            timeout: 20000,
            // url: "http://www.mocky.io/v2/5e3b77f73000005500214596",
            url: `${default_server}/getportfolio/`,
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
          })
            .then((response) => {
              setTimeout(() => props.onComplete(response.data), 1000);
            })
            .catch((error) => {
              setTimeout(() => props.onSubmitError(), 1000);
            })
            .finally(() => {
              setTimeout(() => {
                try {
                  setSubmitting(false);
                } catch (error) { }
                try {
                  setButtonLabel(buttonLabelDefault);
                } catch (error) { }
              }, 1000);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {method.formfieldslogin.map((field) =>
                getFields(
                  field,
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur
                )
              )}

              <button
                id="button-cpf"
                type="submit"
                className="auth_login_button_login"
              >
                <div
                  style={{
                    fontSize: "25px",
                    transform: "skewX(45deg)",
                  }}
                >
                  {BUTTON_LABEL}
                </div>
              </button>
              <button
                id="button-cpf"
                className="auth_login_button_other"
                onClick={() => setMethod(!method)}
              >
                <div
                  style={{
                    fontSize: "25px",
                    transform: "skewX(45deg)",
                  }}
                >
                  Voltar
                </div>
              </button>
              <div className="button-help" onClick={() => setModalOpen(true)}>
                <AiOutlineQuestionCircle size={30} color="#6b6b6b" />
              </div>
              {!phoneInput && <p className="auth_login_error">{errorMsg}</p>}
              <div className="auth_method_contact">
                <div
                  onClick={() => toggle_callus(true)}
                  id="contact"
                  className="auth_call_us"
                >
                  FALE CONOSCO
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
      <Drawer dontApplyListeners={true} open={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <div className="modal">
          <div className="modal-container">
            <p style={{ textAlign: "left" }}>{method.dicalogin}</p>
          </div>
          <div className="modal-button-container">
            <button onClick={() => setModalOpen(false)}>FECHAR</button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AuthMethodLogin;
