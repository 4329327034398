import React from "react";
import "./../../assets/styles/AuthTitle.css";

const AuthTitle = () => {
  return (
    <div className="auth_container">
      <p className="auth_title2">
        DESCUBRA OU REENCONTRE QUEM ESTÁ FAZENDO A LITERATURA BRASILEIRA
        CONTEMPORÂNEA
      </p>
    </div>
  );
};

export default AuthTitle;
