import React, { Component } from "react";
import Section from "../__section";
import Logo from "../logo";
import AuthTitle from "../auth-title";
import Footer from "../footer";
import AuthMethodLogin from "../auth-method";
import Processing from "../processing";
import CallUsModal from "../faleconosco/faleconosco-modal";
import AuthSelector from "../__authselector/index";
import HelpModal from "../help-modal/index";

class AuthPage extends Component {
  static defaultProps = {
    appState: null,
    startDelay: 1000,
    onRefresh: () => console.log("missing refresh callback function"),
  };

  constructor(props) {
    super(props);
    this.processing = React.createRef();
  }

  state = {
    isReady: true,
    loadingRef: null,
    show_callus: false,
    isSuccess: false,
    phoneInput: true,
    welcomeShow: true,
    show_help: false,
  };

  setIsSuccess = (mode) => {
    this.setState({ isSuccess: mode });
  };
  setPhoneInput = (bool) => {
    this.setState({ phoneInput: bool });
  };

  toggle_callus = (mode) => {
    this.setState({ show_callus: mode });
  };
  toggle_help = (mode) => {
    this.setState({ show_help: mode });
  };

  render() {
    const { isReady, welcomeShow } = this.state;
    const appState = this.props.appState;

    const isAppInitialized = appState === "login" ? true : false;

    if (!isAppInitialized || !isReady) {
      return null;
    }

    return (
      <Section parent={this.props}>
        <HelpModal
          toggle_help={this.toggle_help}
          show_help={this.state.show_help}
        />
        <CallUsModal
          show_callus={this.state.show_callus}
          toggle_callus={this.toggle_callus}
          setIsSuccess={this.setIsSuccess}
          isSuccess={this.state.isSuccess}
        />
        <Processing ref={this.processing} />
        <div className="auth-page-content">
          <Logo />
          {welcomeShow ? (
            <>
              <AuthTitle />
              <button
                className="WelcomeButton"
                onClick={() => this.setState({ welcomeShow: !welcomeShow })}
                id="contact"
              >
                <div
                  style={{
                    fontSize: "25px",
                    transform: "skewX(45deg)",
                  }}
                >
                  Começar
                </div>
              </button>
              <button className="RegulationButton" onClick={() => window.location.pathname = '/regulamento'}>
                Regulamento
              </button>
            </>
          ) : (
            <AuthMethodLogin
              onSubmit={() => this.processing.current.show()}
              onSubmitError={() => {
                this.processing.current.hide();
                this.props.onError();
              }}
              onComplete={this.props.onRefresh}
              phoneInput={this.state.phoneInput}
              toggle_callus={this.toggle_callus}
            />
          )}
          {/* <AuthSelector
					phone={this.state.phoneInput}
					setPhoneInput={this.setPhoneInput}
					toggle_help={this.toggle_help}
				/>
				<AuthFormLogin
					onSubmit={() => this.processing.current.show()}
					onSubmitError={() => {
						this.processing.current.hide();
						this.props.onError();
					}}
					onComplete={this.props.onRefresh}
					phoneInput={this.state.phoneInput}
				/> */}
          <Footer />
        </div>
      </Section>
    );
  }
}

export default AuthPage;
