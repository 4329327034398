import React, { useEffect, useState } from 'react';
import TweenOne from 'rc-tween-one';
import Carousel from 'nuka-carousel';
import './../../assets/styles/Carrossel.css';

const Carrossel = (props) => {
	const { currentBookID, payload, changeReader } = props;
	const [ SLIDES, setSlide ] = useState([]);
	const [ slideIndexToShow, setSlideIndex ] = useState(0);
	const [ animationEnd, setAnimationEnd ] = useState(false);
	const [ GIF_INTRO_PATH, SET_GIF_INTRO_PATH ] = useState('');

	useEffect(
		() => {
			const CURRENT_BOOKS = [];
			const listData = payload.listData;
			const totalBooks = listData.length;
			const currentYear = payload.currentYear;
			let bookIdx = 0;

			// se estiver abrindo a aplicação...
			if (currentBookID === 0) {
				for (let i = 0; i < totalBooks; i++) {
					const element = listData[i];
					if (element.bookYear === currentYear) {
						if (element.bookMonthName === payload.currentMonthName) {
							bookIdx = i;
							i = totalBooks;
						}
					}
				}
				// caPtura path do GIF inicial
				SET_GIF_INTRO_PATH(listData[bookIdx].bookCoverGIF);
				setSlideIndex(bookIdx);
				// captura um ID mais recente, aplica e restarta o sistema
				return props.onSlideChange(listData[bookIdx].bookId);
			}

			// descobre o índice a partir do ID do livro
			for (let i = 0; i < totalBooks; i++) {
				const element = listData[i];
				if (currentBookID === element.bookId) {
					bookIdx = i;
					i = totalBooks;
				}
			}

			const ITEM = listData[bookIdx];
			const currentBookMonthName = ITEM.bookMonthName;
			const currentBookYear = ITEM.bookYear;

			// monta o array para o carrossel
			listData.forEach((element) => {
				// seleciona apenas os livros do mesmo mês (e ano)
				if (element.bookYear === currentBookYear && element.bookMonthName === currentBookMonthName) {
					CURRENT_BOOKS.push(
						<div
							id={element.bookId}
							key={element.bookId}
							gif={element.bookCoverGIF}
							className="carrossel_slide"
							style={{ backgroundImage: `url(${element.bookCover})` }}
							onClick={(e) => {
								const selected = CURRENT_BOOKS.find((item) => item.key === e.target.id);
								const indexInCarrossel = CURRENT_BOOKS.indexOf(selected);
								if (element.bookDownloadFile && indexInCarrossel === slideIndexToShow) {
									changeReader(element);
								}
								document.getElementById('main_title_container').scrollIntoView({
									behavior: 'smooth'
								});
								props.onSlideChange(e.target.id);
							}}
						/>
					);
				}
			});

			// descobre o índice do livro escolhido na lista atualizada
			for (let j = 0; j < CURRENT_BOOKS.length; j++) {
				const element = CURRENT_BOOKS[j];
				if (element.key === currentBookID) {
					setSlideIndex(j);
					j = CURRENT_BOOKS.length;
					// captura path do GIF
					SET_GIF_INTRO_PATH(element.props.gif);
				}
			}
			setSlide(CURRENT_BOOKS);
		},
		[changeReader, currentBookID, payload.currentMonthName, payload.currentYear, payload.listData, props, slideIndexToShow]
	);

	return (
		<div id="carrossel" className="carrossel_container">
			<div className="carrossel_slide_container">
				{!animationEnd ? (
					<TweenOne
						animation={{
							opacity: 0,
							delay: 3500,
							onComplete: () => {
								return setAnimationEnd(true);
							}
						}}
					>
						<img src={GIF_INTRO_PATH} alt={'Loading...'} className="book_mount" />
					</TweenOne>
				) : (
					<TweenOne
						className="carrossel_wrap"
						animation={{
							opacity: 1
						}}
					>
						<Carousel
							withoutControls={true}
							width='206.5px'
							height='329.7px'
							slideIndex={slideIndexToShow}
							afterSlide={(index) => {
								const ID = SLIDES[index].key;
								props.onSlideChange(ID);
							}}
						>
							{SLIDES}
						</Carousel>
					</TweenOne>
				)}
			</div>
		</div>
	);
};

export default Carrossel;
