import React from "react";
import logoSvg from "./../../assets/images/logo.png";
import "./../../assets/styles/Logo.css";

const Logo = ({ appState }) => {
  return (
    <div id="logo" className={"container_logo"}>
      <img
        className={appState === 'landingpage' ? 'main_logo_landingpage' : 'main_logo'}
        src={logoSvg}
        alt="Logo"
      />
    </div>
  );
};

export default Logo;
