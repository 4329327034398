import React from 'react';
import Section from '../__section';
import Logo from '../logo';
import MainTitle from '../main-title';
import Carrossel from '../carrossel';
import BookDetail from '../book-detail';
import Review from '../review';
import OthersBooks from '../others-books';
import InfoButtons from '../info-buttons';
import InfoSmallButtons from '../info-small-buttons';
import Footer from '../footer';
import Author from '../author';
import Disclaimer from '../disclaimer';
import ParallaxImage from '../__parallax/ParallaxImage';
import PwaScreen from '../pwa-screen';
import PushNotificationModal from '../pushNotificationModal';

const LandingPage = (props) => {
	const isAppInitialized = props.appState === 'landingpage' ? true : false;

	if (!isAppInitialized) {
		return <div />;
	}

	const changeReader = props.changeReader;

	return (
		<>
			<PushNotificationModal showEnable={props.appState === 'landingpage'} />
			<PwaScreen />

			<Section parent={props}>
				<div key="1">
					<Logo />
					<MainTitle />
					<Carrossel changeReader={changeReader} />
					<BookDetail changeReader={changeReader} />
				</div>

				<div key="2">
					<Review />
					<div />
				</div>

				<div key="3">
					<Author />
					<div />
				</div>

				<div className='section_4' key="4">
					<div className='other_books_top' />
					<div className='other-books-section'>
						<OthersBooks />
						<InfoButtons />
						<InfoSmallButtons />
					</div>
					<div className='other_books_bottom' />
				
					<Disclaimer />
					<Footer />
				</div>

				{/* <ParallaxImage type="parallax" /> */}
			</Section>
		</>
	);
};

export default LandingPage;
